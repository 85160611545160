<template>
  <div>
    <div
      class="modal fade"
      id="ChooseTemplateModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      v-bind="$attrs"
      ref="chooseTemplateRef"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content TemplateModalContent">
          <div class="w-100 px-3 d-flex justify-content-end">
            <button @click="CloseModal" class="btn" style="">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <!-- Modal Content -->
          <div class="container" style="min-height: 50vh">
            <div v-if="isChooseTempLoading">
              <LoaderComp />
            </div>
            <!-- real estate start -->
            <div v-else>
              <div class="container pb-5">
                <!-- If Data is present -->
                <div v-if="Object.keys(Templates).length !== 0">
                  <div class="row justify-content-center mb-3">
                    <div>
                      <p class="Title">Choose a template</p>
                    </div>
                    
                    <div
                      class="d-flex align-items-center justify-content-center w-100"
                    >
                      <svg
                        width="9"
                        height="14"
                        viewBox="0 0 9 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.67355 1.58002L7.48689 0.400024L0.893555 7.00002L7.49355 13.6L8.67355 12.42L3.25355 7.00002L8.67355 1.58002Z"
                          fill="#B3AFB6"
                        />
                      </svg>
                      <div class="px-3">
                        <button
                          class="btn btn-tab"
                          v-for="(category, index) in Templates.categories"
                          :key="index"
                          :class="
                            category.id == Templates.activeCategoryId
                              ? 'active'
                              : null
                          "
                          @click="changeCategory(category.id)"
                        >
                          {{ category.name }}
                        </button>
                      </div>
                      <svg
                        width="9"
                        height="14"
                        viewBox="0 0 9 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.327148 12.42L1.50715 13.6L8.10715 7.00002L1.50715 0.400024L0.327148 1.58002L5.74715 7.00002L0.327148 12.42Z"
                          fill="#B3AFB6"
                        />
                      </svg>
                    </div>
                  </div>

                  <div v-if="filterTemplates.length">
                    <div
                      class="row "
                      style="
                        max-height: 400px;
                        overflow: hidden scroll;
                        scroll-behaviour: smooth;
                      "
                    >
                      <div
                        class="mt-2 col-12 col-md-6 col-lg-5 col-xl-4 col-sm-12 mb-3"
                        v-for="(template, templateIndex) in filterTemplates"
                        :key="templateIndex"
                      >
                        <div
                         @click="chooseTemplate(template)"
                          :class="
                            template.id == activeTemplateId
                              ? 'selected-template'
                              : null
                          "
                          style="
                            box-shadow: 0px 4px 10px rgba(10, 4, 17, 0.15);
                            border-bottom-left-radius: 12px;
                            border-bottom-right-radius: 12px;
                          "
                        >
                          <LazyImage
                            :src="ImageSrc(template.image)"
                            alt=""
                            :key="template.image"
                            style="width: 100%; height: 70%;"
                          />
                          <div class="row m-0">
                            <div class="col-12">
                              <div class="py-2" style="">
                                <div class="">
                                  <p
                                    class="font-weight-bold"
                                    style="font-size: 16px; margin-bottom: 5px"
                                  >
                                    {{ template.name }}
                                  </p>
                                </div>

                                <div>
                                  <p
                                    class=""
                                    style="
                                      font-size: 12px;
                                      margin-bottom: 10px;
                                      overflow: auto;
                                    "
                                  >
                                    {{ template.description }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class=" ">
                            <div
                              class="d-flex justify-content-between px-3 pb-2"
                            >
                              <button
                                @click="chooseTemplate(template)"
                                class="outline-none text-white outline-none border-0 px-4 py-2 choose-btn"
                                style=""
                              >
                                Choose
                              </button>

                              <button
                                v-if="template.quizKey"
                                @click.stop="OpenPreview(template.quizKey)"
                                class="outline-none text-white outline-none border-0 px-3 py-2 see-demo-btn"
                                style=""
                              >
                                Preview
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="pt-2 d-flex justify-content-end">
                      <button
                        class="btn btn-tab active"
                        :disabled="!activeTemplateId"
                        @click="toggleView"
                      >
                        Save Template
                      </button>
                    </div>
                  </div>

                  <div v-else>
                    <p class="text-center" style="font-weight: 600">
                      No template found for selected category!
                    </p>
                  </div>
                </div>

                <!-- If No Data  -->
                <div v-else class="">
                  <h3 class="text-center">No Templates found</h3>
                </div>
              </div>
            </div>
            <!-- real estate start -->
          </div>
        </div>
      </div>
    </div>
  <ConfirmChooseTemplateModal ref="confirmChooseRef" @OpenChooseTemp="updateTemp" @OpenTemp="OpenModal"/>
  </div>
</template>

<script>

import $ from 'jquery'
import { mapGetters } from "vuex";
import axios from "axios";
import LoaderComp from "../customize/component/LoaderComp.vue";
import ConfirmChooseTemplateModal from "./ConfirmChooseTemplateModal.vue"
export default {
  components: {
    LoaderComp,
    ConfirmChooseTemplateModal
  },
  data() {
    return {
      isChooseTempLoading: false,
      Templates: {},
      searchTemplate: "",
      activeTemplateId: null,
    };
  },
  computed: {
    ...mapGetters(["GetTokenFromMetaTag", "getQuizID"]),
    filterTemplates() {
      const Index = this.getSelectedCategoryIndex;
      if (Index > -1) {
        const SelectedTemplates = this.Templates.categories[Index].templates;
        return SelectedTemplates.filter((item) => {
          return (
            this.searchTemplate.length === 0 ||
            item.name.toLowerCase().includes(this.searchTemplate.toLowerCase())
          );
        });
      } else {
        return null
      }
    },
    getSelectedCategoryIndex() {
      const index = this.Templates.categories.findIndex(
        (x) => x.id == this.Templates.activeCategoryId
      );
      return index;
    },
  },
  methods: {
    OpenModal() {
      $("#ChooseTemplateModal").modal("show");
    },

    CloseModal() {
      $("#ChooseTemplateModal").modal("hide");
    },
   async updateTemp(){
     await this.$emit("UpdateQuiz");
      window.location.reload();
      
    },
    async getTemplates() {
      this.isChooseTempLoading = true;
      try {
        let data = {
          _token: this.GetTokenFromMetaTag,
          quizId: this.getQuizID,
        };
        const response = await axios.post(`/getQuizTemplates`, data);
        if (response.status == 200 && response.data.status == "success") {
          this.Templates = response?.data.data;
          this.activeTemplateId = this.Templates.activeTemplateId;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isChooseTempLoading = false;
      }
    },
    changeCategory(id) {
      if (id) {
        this.Templates.activeCategoryId = id;
      }
    },
    ImageSrc(img) {
      return img.startsWith("upload") ? `/${img}` : img;
    },
    chooseTemplate(template) {
      if (template) {
        this.activeTemplateId = template.id;
      }
    },
    toggleView(){
      this.CloseModal()
      this.$refs.confirmChooseRef.OpenModal(this.activeTemplateId)
    },
    // async updateTemplate() {
    //   this.isChooseTempLoading = true;
    //   try {
    //     let data = {
    //       _token: this.GetTokenFromMetaTag,
    //       quizId: this.getQuizID,
    //       templateId: this.activeTemplateId,
    //     };
    //     const response = await axios.post(`changeQuizDesign`, data);
    //     if (response.status == 200 && response.data.status == "success") {
    //       this.$emit("UpdateQuiz");
    //       this.CloseModal();
    //     }
    //   } catch (error) {
    //     if (error) {
    //       this.$toasted.show("Error occured", {
    //         theme: "toasted-primary",
    //         position: "bottom-center",
    //         duration: 2000,
    //       });
    //     }
    //     throw error;
    //   } finally {
    //     this.isChooseTempLoading = false;
    //   }
    // },
    OpenPreview(key) {
      if (key) {
        const url = `/preview/${key}`;
        window.open(url);
      } else {
        this.$toasted.show("Error occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
  },
  mounted() {
    $(this.$refs.chooseTemplateRef).on("show.bs.modal", this.getTemplates);
  },
};
</script>

<style scoped>
.TemplateModalContent {
  border-radius: 20px;
  text-align: left;
}
.Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: #4d4950;
}
.btn-tab {
  background: transparent;
  border: 1px solid #cbcddc;
  color: #4d4950;
  font-weight: 600;
  font-size: 14px;
  margin: 0px 4.5px;
}
.btn-tab.active {
  background: #4d1b7e;
  border: 1px solid #4d1b7e;
  color: #ffffff;
}
.w-90 {
  width: 90%;
}
.clear-page-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-border-bottom {
  border-bottom: 1px solid #cbcddc;
  height: 214px;
}
.see-demo-btn {
  background: transparent;
  border: 1px solid #b3afb6 !important;
  color: #6d6b6d !important;
  border-radius: 6px;
  padding: 8px 42px !important;
}
.see-demo-btn:hover {
  border: 1px solid #b3afb6 !important;
  background: #b3afb6 !important;
  color: #ffffff !important;
}
.choose-btn {
  background: #4d4950;
  border: 1px solid #4d4950;
  border-radius: 6px;
  padding: 8px 42px !important;
}
.choose-btn:hover {
  border: 1px solid #4d4950;
  background: #4d4950cc;
  color: #ffffff !important;
}

.chooseTemplateInput {
  border: 0.8px solid #b3afb6;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
}
.chooseTemplateInput input {
  background: transparent;
  border: none;
  outline: none;
}
.chooseTemplateInput input:focus {
  border: none;
  outline: none;
}
.selected-template {
  border: 4px solid #4d1b7e;
  border-radius: 12px;
}
</style>
